<template>
  <v-container fluid>
       <v-btn class="ma-2" tile large color="green darken-2" @click="printform()" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
<h4>{{currentCase.participantDetails.firstname}}'s Goals (Summary)</h4>
    <v-row>
      <v-col cols="12">
        <v-data-table
          id="list-table"
          ref="datatable"
          :headers="headers"
          :items="goals"
          :loading="loading"
          item-key="index"
          class="mr-2"
          :hide-default-footer="true"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.title}}</td>
              <td>{{ props.item.description}}</td>
              <td>{{ props.item.status}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as goalService from "../../../../../services/goalService";
Vue.use(VeeValidate);

export default {
  name: "goals",

  data: () => ({
    vm: {},
    dialog: false,
    valid: true,
    goals: [],
    totalItems: 0,
    loading: false,
    queryFilters: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "Status", value: "status" },
      { text: "", value: "actions" }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentGoal(state) {
      return state.currentGoal;
    }
  },
  methods: {
        printform(){
      print()
    },
    init() {
      this.vm = {};
      goalService
        .list(this.currentOrganisation.id, this.currentCase.id)
        .then(result => {
          let goals = [];
          result.docs.map(doc => {
            const goal = doc.data();
            goal.id = doc.id;
            goals.push(goal);
          });
          this.goals = goals;
        });
    },

    editItem(item) {
      this.$store.commit("setCurrentGoal", item);
      this.$router.push("/goal-details");
    },
    addGoal() {
      this.$store.commit("setCurrentGoal", null);
      this.$router.push("/goal-details");
    },
    deleteItem(item) {
      const index = this.currentCase.goals.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.currentCase.goals.splice(index, 1);
    },

    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


